import React, { useState } from "react"
import Layout from '../components/Layout'
import { Link } from "gatsby"
import loadable from '@loadable/component'

import Card from "../components/Card/Card";
import CallToAction from "../components/CallToAction";

import { pageLink, conversionId, googleConversion } from '../utils'

import TelegramSvg from '../assets/svg/telegram.svg';
import RedditSvg from '../assets/svg/reddit.svg';
import DiscordSvg from '../assets/svg/discord.svg';
import TwitterSvg from '../assets/svg/twitter.svg';

import nativeImg from '../assets/images/native.png';
import goodsImg from '../assets/images/goods.png';
import paymentImg from '../assets/images/payment.png';
import circuitImg from '../assets/images/circuit.png'

import businessImg from '../assets/images/business_321.jpg';
import communityImg from '../assets/images/community_321.jpg';
import galleriesImg from '../assets/images/galleries_321.jpg';
import personalImg from '../assets/images/personal_321.jpg';
import productImg from '../assets/images/product_321.jpg';
import showroomImg from '../assets/images/showroom_321.jpg';


import {
  demoLink
} from '../utils'

import './index.scss';

const ModalVideo = loadable(() => import('react-modal-video'))

const highLightBoxData = [
  { title: "Native and Immersive", 
    text: "A user experience designed specifically for the Metaverse and optimized for VR, AR and MR.",
    icon: nativeImg},
  { title: "No Coding", 
  text: "Set up your store or space in 3 minutes with zero coding",
  icon: circuitImg },
  { title: "Sell To 100+ Million Crypto Natives", 
  text: "Metaverse commerce is expected to be worth Trillions",
  icon: paymentImg },
  { title: "Convenience for Sellers & Creators", 
    text: "Easy to customize and update you storefront or space",
    icon: goodsImg },

]

const CtaBoxData = [
  {title: "Double Community", text: "Get the latest news and updates via our channels including Discord, Telegram, Twitter and more.", buttonText: "Get Connected", toLink: pageLink.community},
  {title: "About Double", text: "Get to know more about us through our mission, vision and values.", buttonText: "LEARN MORE", toLink: pageLink.about},
  {title: "Developer Resources", text: "Documentation, protocol specification, demos, tutorials and more to get you up to speed.", buttonText: "START BUILDING", toLink: pageLink.developers},
  {title: "Sellers’ Hub", text: "Resources for brands, developers and merchants who are ready to take on the Metaverse.", buttonText: "LEARN MORE", hrefLink: pageLink.doubleStore},
]

const metaverseCards = [
  {
    title: "Sell Products",
    description: "A new and essential revenue stream for any business",
    img: productImg,
  },
  {
    title: "Build Your Business",
    description: "Establish your brand presence in the Metaverse",
    img: businessImg,
  },
  {
    title: "Create a Community",
    description: "Bring together like-minded people from around the world",
    img: communityImg,
  },
  {
    title: "Galleries",
    description: "Display NFTs, digital art, crypto holdings and more",
    img: galleriesImg,
  },
  {
    title: "Showrooms",
    description: "Showcase your product, app or website",
    img: showroomImg,
  },
  {
    title: "Personal Spaces",
    description: "Ideal for creators, freelancers and individuals",
    img: personalImg,
  },
]

const DataBox = ({icon, boxTitle, boxText, boxButtonText, hrefLink, toLink}) => (
  <div className={`box ${boxTitle.split(" ")[0].replaceAll(/\'/g, " ").toLowerCase()}`}>
    { icon && 
      <div className="box__image-wrapper">
        <img className="box__image-wrapper__icon" src={ icon } alt="" />
      </div>
    }
    <h2 className="box__title">{ boxTitle }</h2>
    <p className="box__text">{ boxText }</p>
    { boxButtonText && hrefLink &&  
      <a className="button button-100" href={hrefLink} target="_blank" rel="noreferrer" aria-label={boxTitle}>{ boxButtonText }</a> }
    { boxButtonText && toLink && 
      <Link className="button button-100" to={ toLink }>{ boxButtonText }</Link> }
  </div>
)


// markup
const IndexPage = () => {
  const [isOpen, setOpen] = useState(false)

  const handleVideoClick = () => {
    setOpen(true)
    googleConversion(conversionId.watchVideo)
  }
  return (
    <Layout
      title="Double | Home"
      pageClassName="page-home"
      description= "Create Your Metaverse Store Today"
    >
      <section className="page-home__section-hero">
        <div className="page-home__section-hero__container">
          <div className="position-wrapper">
            <h1 className="page-title">Build new opportunities in the Metaverse</h1>
            {/* <p className="page-title-tag">Empowering everyone to buy and sell in the Metaverse</p> */}
            <div className="button-group">
              <a className="button button-200 hero-button primary" href={pageLink.store} target="_blank" rel="noreferrer" aria-label="start now" onClick={() => googleConversion(conversionId.startNow)}>start now</a>
              <ModalVideo
                channel='youtube'
                autoplay
                isOpen={isOpen}
                videoId={pageLink.youtubeId}
                onClose={() => setOpen(false)}
              />
              <button
                className="button button-200 hero-button"
                onClick={handleVideoClick}
              >
                Watch Video
              </button>
              {/* <a className="button button-200 hero-button" href={pageLink.youtube} target="_blank" rel="noreferrer" aria-label="watch video" onClick={() => googleConversion(conversionId.watchVideo)}>watch video</a> */}
            </div>
          </div>
          <div className="social-media-group">
            <a className="icon-link reddit" href={pageLink.telegram} target="_blank" rel="noreferrer" aria-label="Telegram"><TelegramSvg /></a>
            <a className="icon-link twitter" href={pageLink.twitter} target="_blank" rel="noreferrer" aria-label="Twitter"><TwitterSvg /></a>
            <a className="icon-link discord" href={pageLink.discord} target="_blank" rel="noreferrer" aria-label="Discord"><DiscordSvg /></a>
          </div>
        </div>
      </section>
      <section className="page-home__section-opportunity">
        <div className="page-home__section-opportunity__container">
          <h1 className="section-title">Create Your Metaverse Store Today</h1>
          <p className="section-title-tag">Double isn’t just revolutionizing commerce. We’re empowering anyone and everyone to create their own Metaverse space.</p>
          <div className="cards">
            {metaverseCards.map(card => <Card key={card.title} title={card.title} description={card.description} image={card.img}/>)}
          </div>
        </div>
      </section>
      <section className="page-home__section-highlights">
        <div className="page-home__section-highlights__container">
          { highLightBoxData.map(el => (
              <DataBox key={ el.title } boxTitle={ el.title } boxText={ el.text } icon={ el.icon} />
          ))}
        </div>
      </section>
      <section className="page-home__section-mission">
        <div className='page-home__section-mission__container'>
          <div className="container-left">
            <h1 className="title">Our Mission</h1>
            <p className="content">Double is on a mission to revolutionize the future of commerce and community for the Metaverse. We want anyone and everyone to be able to buy and sell in the Metaverse, build exhibition spaces, galleries, community hubs and experiences - all without coding. </p>
          </div>
          <div className="callout">
            <div className="callout--inner">
              We provide the tools, what you build is limited only by your imagination. 
            </div>
          </div>
        </div>
      </section>
      <CallToAction callBack={() => googleConversion(conversionId.getStarted)} />
    </Layout>
  )
}

export default IndexPage
