import React from 'react'
import * as styles from "./Card.module.scss"

const Card = ({title, description, image }) => {
  return (
    <div className={`card ${styles.card}`}>
      <img className={`image ${styles.img}`} src={image} alt="" />
      <h3 className={`title ${styles.title}`}>{title}</h3>
      <p className={`description ${styles.description}`}>{description}</p>
    </div>
  )
}

export default Card